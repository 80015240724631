import GoodsList from '@src/Components/GoodsList';
import GoodsListPreview from '@src/Components/GoodsListPreview';
import GoodsRequest from '@src/Components/GoodsRequest';
import GoodsRequestComplete from '@src/Components/GoodsRequestComplete';
import { Route, Router, Switch } from '@src/Utils/Routing';
import React, { Component } from 'react';
import { ThemeProvider } from 'react-native-elements';

class App extends Component {
    render() {
        return (
            <ThemeProvider>
                <Router>
                    <Switch>
                        <Route exact path="/" component={GoodsList} />
                        <Route exact path="/preview/:item_id" component={GoodsListPreview} />
                        <Route exact path="/request" component={GoodsRequest} />
                        <Route exact path="/request/complete" component={GoodsRequestComplete} />
                    </Switch>
                </Router>
            </ThemeProvider>
        );
    }
}

export default App;