export default {
    input: {
        containerStyle: {
            marginBottom: 10
        },
        inputStyle: {
            height: 46
        }
    },
    button: {
        containerStyle: {
            paddingHorizontal: 10
        }
    },
    container: {
        alignItems: 'center',
        backgroundColor: '#fff',
        display: 'flex',
        flex: 1,
        width: '100%',
        height: '100%',
        justifyContent: 'center'
    }
};