import React from 'react';
import { View } from 'react-native';
import { Input, Text, withTheme } from 'react-native-elements';
import { compose } from 'redux';

const InputStyle = { 
    width: '100%',
    textAlign: 'center',
    fontSize: 32
}

class PhoneInput extends React.PureComponent {
    state = {
        phone_error_message: ' ',
        phone1: '010',
        phone2: '',
        phone3: '',
        phone: ''
    }

    phoneInput1 = React.createRef()
    phoneInput2 = React.createRef()
    phoneInput3 = React.createRef()

    render() {
        return (
            <View style={{ ...this.props.style, justifyContent: 'space-between' }}>
                <View style={{ paddingTop: 140, paddingBottom: 8, paddingLeft: 20, paddingRight: 20, flexDirection: 'row' }}>
                    <Input
                        ref={this.phoneInput1}
                        keyboardType={'phone-pad'}
                        maxLength={3}
                        placeholder={'010'}
                        containerStyle={{ flex: 1 }}
                        inputStyle={InputStyle}
                        onChangeText={text => this.onChangePhone1(text)}
                        value={this.state.phone1}
                    />
                    <Text style={{ fontSize: 36 }}>-</Text>
                    <Input
                        ref={this.phoneInput2}
                        keyboardType={'phone-pad'}
                        maxLength={4}
                        placeholder={'1234'}
                        containerStyle={{ flex: 1 }}
                        inputStyle={InputStyle}
                        onChangeText={text => this.onChangePhone2(text)}
                        value={this.state.phone2}
                    />
                    <Text style={{ fontSize: 36 }}>-</Text>
                    <Input
                        ref={this.phoneInput3}
                        keyboardType={'phone-pad'}
                        maxLength={4}
                        placeholder={'5678'}
                        containerStyle={{ flex: 1 }}
                        inputStyle={InputStyle}
                        onChangeText={text => this.onChangePhone3(text)}
                        value={this.state.phone3}
                    />
                </View>
                <Text style={{ padding: 20, textAlign: 'left', color: 'red' }}>{this.state.phone_error_message}</Text>
            </View>
        )
    }

    onChangePhone1 = (phone1) => {
        this.setState({ phone1 }, this.validatePhoneNumber)
        if (phone1.length === 3) {
            this.phoneInput2.current.focus()
        }
    }

    onChangePhone2 = (phone2) => {
        this.setState({ phone2 }, this.validatePhoneNumber)
        if (phone2.length === 4) {
            this.phoneInput3.current.focus()
        }
        if (phone2.length === 0) {
            this.phoneInput1.current.focus()
        }
    }

    onChangePhone3 = (phone3) => {
        this.setState({ phone3 }, this.validatePhoneNumber)
        if (phone3.length === 0) {
            this.phoneInput2.current.focus()
        }
    }

    validatePhoneNumber = () => {
        let phone = `${this.state.phone1}-${this.state.phone2}-${this.state.phone3}`
        const regEx = /[0][1][0,1,6,7,8,9]-\d{3,4}-\d{4}/
        let phone_error_message = ''
        if (regEx.test(phone) === false) {
            phone_error_message = '휴대폰 번호를 정확하게 입력해주세요.'
            phone = ''
        } else {
            phone_error_message = ''
            this.setState({ phone })
        }
        this.setState({
            phone,
            phone_error_message
        })
    }

    phoneNumber = () => {
        return this.state.phone
    }
}

export default compose(
    withTheme
)(PhoneInput);