export default class Goods {
    constructor(data) {
        this.id = data.id
        this.username = data.username
        this.name = data.name
        this.profile_url = data.profile_url
        this.description = data.description
        this.price = data.price
        this.service_charge_rate = data.service_charge_rate
    }

    priceText = () => (`₩${this.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`)
    serviceCharge = () => (this.price * this.service_charge_rate)
    serviceChargeText = () => (`₩${this.serviceCharge().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`)
    totalText = () => {
        const total = this.price + this.serviceCharge()
        return `₩${total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    }
}