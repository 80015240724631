import styles from '@src/Components/Shared.style';
import React, { PureComponent } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Header } from 'react-native-elements';
import Icon from 'react-native-vector-icons/FontAwesome';

export const HEADER_HEIGHT = 74

const containerStyle = {
    alignItems: 'center',
    height: HEADER_HEIGHT,
    justifyContent: 'center',
    paddingHorizontal: 0,
    paddingTop: 0,
    width: '100%'
};

const centerContainerStyle = {};

const buttonStyle = {
    alignItems: 'center',
    height: 48,
    justifyContent: 'center',
    paddingRight: 5,
    width: 40
};

const textStyle = { flex: 1, color: '#fff', fontSize: 18, fontWeight: 'bold' };

const withHeader = ({ title = '', right = null, left = null }) => (WrappedComponent) => {
    class WithHeaderHOC extends PureComponent {
        horizontalComponent = (data) => (
            <TouchableOpacity onPress={data.action.bind(this, this.props)} style={buttonStyle}>
                <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                    <Icon name={data.icon} size={data.iconSize} color='#fff' />
                </View>
            </TouchableOpacity>
        );

        centerComponent = (title) => ({
            text: typeof title === 'function' ? title().toUpperCase() : title.toUpperCase(),
            style: textStyle
        });

        render() {
            return (
                <View style={[styles.container, { position: 'absolute', top: 0 }]}>
                    <Header
                        // TODO: native
                        // statusBarProps={{ barStyle: 'light-content' }}
                        containerStyle={containerStyle}
                        centerContainerStyle={centerContainerStyle}
                        leftComponent={left ? this.horizontalComponent(left) : null}
                        centerComponent={this.centerComponent(title)}
                        rightComponent={right ? this.horizontalComponent(right) : null}
                    />
                    <WrappedComponent {...this.props} />
                </View>
            );
        }
    }

    return WithHeaderHOC;
}

export default withHeader;
