import withHeader, { HEADER_HEIGHT } from '@src/HOCs/withHeader';
import React from 'react';
import { Dimensions, View, ScrollView } from 'react-native';
import { Text, withTheme, Button } from 'react-native-elements';
import { compose } from 'redux';
import ContentHeader from './ContentHeader';

const screenHeight = Math.round(Dimensions.get('window').height);

class GoodsRequestExample extends React.PureComponent {
    render() {
        return (
            <ScrollView style={{ width: '100%', height: screenHeight - HEADER_HEIGHT }}>
                <View style={{ flex: 1, width: '100%', height: '100%' }}>
                    <ContentHeader title={'주제 정하기 예시'} description={'주제는 되도록 자세히 써주는것이 좋아요.'} />
                    <View style={{ padding: 8 }}>
                        <Text h3>축하</Text>
                        <View style={{ width: '100%', backgroundColor: this.props.theme.colors.grey5 }}>
                            <View style={{ width: '100%', padding: 8 }}>
                                <Text>{`길동이가 2월 20일에 23번째 생일이에요. 요즘 학교 성적이 안나와서 힘든데, 잘 될 거라고 이야기 해주세요. 길동이는 중학교 시절부터 terry씨 엄청 난 팬이에요. 브로마이드부터 나오는 광고의 대사끼자 외우고 다녔어요.`}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={{ padding: 8 }}>
                        <Text h3>위로</Text>
                        <View style={{ width: '100%', backgroundColor: this.props.theme.colors.grey5 }}>
                            <View style={{ width: '100%', padding: 8 }}>
                                <Text>{`길동이가 지난달에 정말로 사랑하던 여자친구와 헤어졌어요. 제가 보기에는 길동이가 너무 아까웠는데, 차이고 나서부터 애가 힘이 없어요. 힘을 좀 낼 수 있게 위로해주세요.\n\n길동이는 중학교 시절부터 terry씨 엄청난 팬이에요. 브로마이드부터 나오는 광고의 대사끼자 외우고 다녔어요.`}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
                <Button
                    style={{ flex: 1, padding: 13 }}
                    title={'확인'}
                    type='solid'
                    onPress={
                        this.props.onPressClose
                            ? this.props.onPressClose
                            : () => { }
                    }
                />
            </ScrollView>
        )
    }
}

export default compose(
    withHeader({
        left: {
            icon: 'close',
            iconSize: 15,
            action: (props) => props.onPressClose()
        }
    }),
    withTheme
)(GoodsRequestExample)