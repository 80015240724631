import GoodsModel from '@src/Models/Goods';

const GoogleSpreadsheet = require('google-spreadsheet');
const moment = require('moment');

export async function get() {
    const goods = require("./Goods.json").goods
    return goods.map(t => new GoodsModel(t))
}

export async function getById(id) {
    const goods = await get()
    const good = goods.find(t => t.id === id)
    return good
}

export async function request(goodsData, userData) {
    return new Promise((resolve, reject) => {
        const doc = new GoogleSpreadsheet("1mpttsbOIdRPxoi0fJwhL1qFR36e0b3oRFGjnjFD_aDg")

        const creds = {
            client_email: "video-goods-service-account@video-goods-253912.iam.gserviceaccount.com",
            private_key: JSON.parse(`"${process.env.GOOGLE_API_KEY}"`)
        }

        doc.useServiceAccountAuth(creds, function (err) {
            if (err) {
                reject(new Error('잘못된 인증정보입니다.'))
            }
            // meta: { phone, subject, price, service_charge, total }
            doc.addRow(1, {
                item_id: goodsData.id,
                receiver: userData.receiver,
                price: goodsData.price,
                service_charge: goodsData.serviceCharge(),
                total: goodsData.price + goodsData.serviceCharge(),
                phone: userData.phone,
                subject: userData.subject,
                created_at: moment().format('YYYY-MM-DD hh:mm:ss')
            }, function () {
                // do nothing
                resolve()
            })
        })
    })
}