import React from 'react';
import { View } from 'react-native';
import { Text, withTheme } from 'react-native-elements';
import { compose } from 'redux';

class TextField extends React.PureComponent {
    render() {
        return (
            <View style={{ paddingLeft: 8, paddingRight: 8, paddingTop: 8, width: '100%', flexDirection: 'row' }}>
                <Text style={{ fontSize: 15, flex: 3, fontWeight: 'bold' }}>{this.props.label}</Text>
                <Text style={{ fontSize: 15, flex: 1, textAlign: 'end' }}>{this.props.text}</Text>
            </View>
        )
    }
}

export default compose(
    withTheme
)(TextField);
