import React from 'react';
import { GoodsAPI } from '@src/APIs'

export default (resource, options) => Component => class FakeFetchHOC extends React.Component {
    _isMounted;

    getUrl = () => {
        let url = resource;
        if (typeof resource === 'function') {
            url = resource(this.props);
        }

        return url;
    };

    state = {
        // Ensure only truthy URLs start off as loading (#3)
        loading: !!this.getUrl(),
        success: undefined,
        error: undefined,
    };

    prevUrl = this.getUrl();

    componentDidMount = () => {
        this._isMounted = true;
        this.fetchData(this.getUrl());
    };

    componentDidUpdate() {
        if (typeof resource === 'function' && this.urlHasChanged()) {
            this.fetchData(this.getUrl());
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    refetchData = () => {
        this.fetchData(this.getUrl());
    };

    fetchData = url => {
        if (!url) return;

        // About to start fetching, set loading state
        this.setState(() => ({
            loading: true,
            success: undefined,
            error: undefined,
            response: undefined,
        }));

        let params = undefined

        switch (true) {
            case /goods\/preview\/(\d+)/gi.test(url):
                params = /goods\/preview\/(\d+)/gi.exec(url)
                GoodsAPI.getById(params[1]).then(data => {
                    if (!this._isMounted) return;

                    this.setState({
                        data: [data],
                        error: undefined,
                        loading: false,
                        success: true
                    })
                }).catch(error => {
                    if (!this._isMounted) return;

                    this.setState({
                        error,
                        loading: false,
                        success: false
                    })
                })
                break;
            case /goods\/(\d+)/gi.test(url):
                params = /goods\/(\d+)/gi.exec(url)
                GoodsAPI.getById(params[1]).then(data => {
                    if (!this._isMounted) return;

                    this.setState({
                        data,
                        error: undefined,
                        loading: false,
                        success: true
                    })
                }).catch(error => {
                    if (!this._isMounted) return;

                    this.setState({
                        error,
                        loading: false,
                        success: false
                    })
                })
                break;
            case /goods/.test(url):
                GoodsAPI.get().then(data => {
                    if (!this._isMounted) return;

                    this.setState({
                        data,
                        error: undefined,
                        loading: false,
                        success: true
                    })
                }).catch(error => {
                    if (!this._isMounted) return;

                    this.setState({
                        error,
                        loading: false,
                        success: false
                    })
                })
                break;
            default:
                this.setState({
                    data: undefined,
                    error: "Not implemented exception",
                    loading: false,
                    success: false
                })
                break;
        }
    };

    urlHasChanged = () => {
        if (typeof resource !== 'function') {
            return this.prevUrl !== resource;
        }

        const currentUrl = resource(this.props);
        if (this.prevUrl !== currentUrl) {
            this.prevUrl = currentUrl;
            return true;
        }

        return false;
    };

    render() {
        return (<Component
            {...this.props}
            {...this.state}
            fetch={this.refetchData}
        />);
    }
};