import styles from '@src/Components/Shared.style';
import fetch from '@src/HOCs/fetch';
import withHeader from '@src/HOCs/withHeader';
import { withRouter } from '@src/Utils/Routing';
import React from 'react';
import { ActivityIndicator, Dimensions, FlatList, View } from 'react-native';
import { Button, Card, Image, Text, withTheme } from 'react-native-elements';
import { compose } from 'redux';

const screenWidth = Math.round(Dimensions.get('window').width);

class GoodsList extends React.PureComponent {
    keyExtractor = (item) => item.id

    renderContent = () => (
        this.props.loading ?
            <ActivityIndicator color='87ceeb' /> :
            this.renderFlatList()
    )

    renderItem = ({ item }) => (
        <Card key={item.id} style={{ margin: 0 }} containerStyle={{ marginLeft: 4, marginRight: 4, marginTop: 8, padding: 0 }}>
            <View style={{ width: '100%' }}>
                <Image
                    style={{
                        flex: 1,
                        width: screenWidth - 10,
                        height: screenWidth - 10,
                        borderColor: this.props.theme.colors.grey5,
                        borderWidth: 1
                    }}
                    source={{ uri: item.profile_url }}
                    PlaceholderContent={<ActivityIndicator color={'blue'} />}
                    placeholderStyle={{ backgroundColor: 'white' }}
                />
                <View style={{ width: '100%', paddingLeft: 20, paddingRight: 8, paddingTop: 8, paddingBottom: 8 }}>
                    <Text h4>{item.name}</Text>
                    <Text style={{ paddingTop: 8 }} numberOfLines={5} ellipsizeMode={'tail'}>{item.description.slice(0, 100)}</Text>
                </View>
                <View style={[styles.container, { paddingTop: 12, paddingBottom: 12, flexDirection: 'row', justifyContent: 'space-between' }]}>
                    <Text style={{ paddingLeft: 20 }}>{item.priceText()}</Text>
                    <Button
                        containerStyle={{ paddingRight: 8 }}
                        title={`굿즈 신청`}
                        titleStyle={{ paddingRight: 35, paddingLeft: 35}}
                        onPress={this.onPressCard.bind(this, item)}
                    />
                </View>
            </View>
        </Card>
    )

    renderFlatList = () => (
        this.props.error ?
            <Text h4>Error: {this.props.data.message || 'Something went wrong 😕'}</Text> :
            <FlatList
                keyExtractor={this.keyExtractor}
                data={this.props.data}
                renderItem={this.renderItem}
                style={{ width: "100%" }}
            />
    )

    render() {
        return (
            <View style={styles.container}>
                {this.renderContent()}
            </View>
        );
    }

    onPressCard = (item) => (
        this.props.history.push('/request', { item_id: item.id })
    )
}

export default compose(
    withHeader({ title: "비디오굿즈 샵" }),
    withRouter,
    fetch((props) => `/goods/preview/${props.match.params.item_id}`),
    withTheme
)(GoodsList);
