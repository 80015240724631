import React from "react";
import { View } from "react-native";
import { Button, Text } from "react-native-elements";
import { compose } from "redux";

class ContentFooter extends React.PureComponent {
    render() {
        return (
            <View style={{ flex: 1, width: "100%", flexDirection: "row" }}>
                <View style={{ flex: 1 }}>
                    {this.props.visibleSecondaryButton && (
                        <Button
                            style={{ flex: 1, padding: 13 }}
                            title={this.props.secondaryButtonText}
                            type="solid"
                            onPress={
                                this.props.onPressSecondaryButton
                                    ? this.props.onPressSecondaryButton
                                    : () => { }
                            }
                        />
                    )}
                </View>
                <Text
                    style={{
                        flex: 1.5,
                        fontSize: 18,
                        fontWeight: "bold",
                        alignSelf: "center",
                        textAlign: 'center',
                        color: this.props.textColor
                            ? this.props.textColor
                            : "black",
                        padding: 13
                    }}
                >
                    {this.props.text}
                </Text>
                <View style={{ flex: 1 }}>
                    {this.props.visiblePrimaryButton && (
                        <Button
                            style={{ flex: 1, padding: 13 }}
                            title={this.props.primaryButtonText}
                            type="solid"
                            onPress={
                                this.props.onPressPrimaryButton
                                    ? this.props.onPressPrimaryButton
                                    : () => { }
                            }
                        />
                    )}
                </View>
            </View>
        );
    }
}

export default compose()(ContentFooter);
