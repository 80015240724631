import withHeader, { HEADER_HEIGHT } from '@src/HOCs/withHeader';
import { withRouter } from '@src/Utils/Routing';
import React from 'react';
import { Dimensions, View } from 'react-native';
import { Text, withTheme, Button, Icon } from 'react-native-elements';
import { compose } from 'redux';

const screenHeight = Math.round(Dimensions.get('window').height);
const BUTTON_HEIGHT = 70

class GoodsRequestComplete extends React.PureComponent {
    render() {
        return (
            <React.Fragment>
                <View style={{ width: '100%', height: screenHeight - HEADER_HEIGHT - BUTTON_HEIGHT, justifyContent: 'center' }}>
                    <View style={{ padding: 8 }}>
                        <Icon name={'check'} type={'font-awesome'} size={150} color='#000' />
                    </View>
                    <Text h3 h3Style={{ padding: 15, textAlign: 'center' }}>{'감사합니다.'}</Text>
                    <Text style={{ paddig: 8, textAlign: 'center' }}>{'신청이 완료되었습니다.'}</Text>
                </View>
                <View style={{ width: '100%', height: BUTTON_HEIGHT, justifyContent: 'center' }}>
                    <Button
                        style={{ flex: 1, padding: 13 }}
                        title={'확인'}
                        type='solid'
                        onPress={this.onPressOK.bind(this)}
                    />
                </View>
            </React.Fragment>
        )
    }

    onPressOK = () => (this.props.history.goBack())
}

export default compose(
    withHeader({}),
    withRouter,
    withTheme
)(GoodsRequestComplete)