import React from 'react';
import { View } from 'react-native';
import { Text, withTheme } from 'react-native-elements';
import { compose } from 'redux';

class ContentHeader extends React.PureComponent {
    render() {
        return (
            <View style={{
                padding: 20,
                backgroundColor: this.props.theme.colors.primary }}
            >
                <Text h3 h3Style={{ color: 'white' }}>{this.props.title}</Text>
                <Text style={{ fontSize: 18, color: 'white' }}>{this.props.description}</Text>
            </View>
        )
    }
}

export default compose(
    withTheme
)(ContentHeader);