import { GoodsAPI } from '@src/APIs';
import styles from '@src/Components/Shared.style';
import fetch from '@src/HOCs/fetch';
import withHeader, { HEADER_HEIGHT } from '@src/HOCs/withHeader';
import { withRouter } from '@src/Utils/Routing';
import React from 'react';
import { ActivityIndicator, Dimensions, ScrollView, TextInput, View } from 'react-native';
import { Button, Divider, Image, Overlay, Text, withTheme } from 'react-native-elements';
import { compose } from 'redux';
import ContentFooter from './ContentFooter';
import ContentHeader from './ContentHeader';
import GoodsRequestExample from './GoodsRequestExample';
import PhoneInput from './PhoneInput';
import TextField from './TextField';

const screenWidth = Math.round(Dimensions.get('window').width);
const screenHeight = Math.round(Dimensions.get('window').height);
const MAX_SUBJECT_LENGTH = 420
const PAGE_FOOTER_HEIGHT = 100

class GoodsRequest extends React.PureComponent {
    state = {
        step: 0,
        receiver: '',
        subject: '',
        error_message: '',
        showExample: false,
        phone: ''
    }

    phoneInput = React.createRef()

    render() {
        if (!this.props.data) {
            return null
        }
        return (
            // TODO: KeyboardAvoidingView
            <React.Fragment>
                <View style={styles.container}>
                    <ScrollView style={{ width: '100%', height: screenHeight - HEADER_HEIGHT }}>
                        {this.renderByStep()}
                    </ScrollView>
                    {
                        this.state.showExample && (
                            <Overlay
                                overlayStyle={{ padding: 0 }}
                                isVisible
                                fullScreen={true}
                                animationType='slide'>
                                <GoodsRequestExample
                                    onPressClose={this.onPressExampleClose} />
                            </Overlay>
                        )
                    }
                </View>
            </React.Fragment>
        );
    }

    renderByStep = () => {
        const key = `renderStep${this.state.step}`
        if (this[key]) {
            return this[key]()
        }
        return null
    }

    renderStep0 = () => (
        <View style={{ flex: 1, width: '100%', height: '100%' }}>
            <ContentHeader title={'사연 입력'} description={'축하,위로등 받고 싶은 내용을 입력해주세요.'} />
            <View style={{ padding: 8 }}>
                <Text h4 style={{ paddingBottom: 8 }}>이름</Text>
                <TextInput
                    editable
                    maxLength={10}
                    style={{
                        padding: 8,
                        borderColor: this.props.theme.colors.primary,
                        borderWidth: 1,
                        fontSize: 18
                    }}
                    placeholder={'영상을 받을 사람의 이름'}
                    onChangeText={text => this.onChangeReceiver(text)}
                    value={this.state.receiver}
                />
                <Text h4 style={{ paddingTop: 20, paddingBottom: 8 }}>내용</Text>
                <TextInput
                    editable
                    multiline
                    maxLength={420}
                    style={{
                        padding: 8,
                        borderColor: this.props.theme.colors.primary,
                        borderWidth: 1,
                        height: 200,
                        fontSize: 18
                    }}
                    placeholder={'입력하기'}
                    onChangeText={text => this.onChangeSubject(text)}
                    value={this.state.subject}
                />
            </View>
            <View style={{ margin: 8, paddingLeft: 12, paddingRight: 8, flexDirection: 'row', justifyContent: 'space-around' }}>
                <Text style={{ flex: 4, fontSize: 18, color: 'red', textAlign: 'left' }}>{this.state.error_message}</Text>
                <Text style={{ flex: 1, fontSize: 18, fontWeight: 'bold', textAlign: 'end' }}>{`${this.state.subject.length}/${MAX_SUBJECT_LENGTH}`}</Text>
            </View>
            <ContentFooter
                visibleSecondaryButton={this.state.step > 0 ? true : false}
                secondaryButtonText={'이전'}
                onPressSecondaryButton={this.onPressPrevStep}
                visiblePrimaryButton={true}
                primaryButtonText={'다음'}
                onPressPrimaryButton={this.onPressNextStep}
                text={`1 / 2`} />
            {this.renderFooterByStep()}
        </View>
    )

    validateStep0 = () => {
        let error_message = this.validateReceiver(this.state.receiver)
        if (error_message) {
            this.setState({ error_message })
            return false
        }
        error_message = this.validateSubject(this.state.subject)
        if (error_message) {
            this.setState({ error_message })
            return false
        }
        return true
    }

    renderStep1 = () => (
        <View style={{ flex: 1, width: '100%', height: '100%' }}>
            <ContentHeader title={'연락처'} description={'문자로 영상을 보냅니다.'} />
            <PhoneInput ref={this.phoneInput} style={{ height: 330 }} />
            <ContentFooter
                visibleSecondaryButton={this.state.step > 0 ? true : false}
                secondaryButtonText={'이전'}
                onPressSecondaryButton={this.onPressPrevStep}
                visiblePrimaryButton={true}
                primaryButtonText={'다음'}
                onPressPrimaryButton={this.onPressNextStep}
                text={`2 / 2`} />
        </View>
    )

    validateStep1 = () => {
        if (this.phoneInput.current) {
            this.phoneInput.current.validatePhoneNumber()
            const valid = this.phoneInput.current.phoneNumber() !== ''
            if (valid) {
                this.setState({ phone: this.phoneInput.current.phoneNumber() })
            }
            return valid
        }
        return false
    }

    renderStep2 = () => (
        <View style={{ flex: 1, width: '100%', height: '100%' }}>
            <Image
                style={{ flex: 1, width: screenWidth, height: screenWidth, marginTop: 8 }}
                source={{ uri: this.props.data.profile_url }}
                PlaceholderContent={<ActivityIndicator color={'blue'} />}
                placeholderStyle={{ backgroundColor: 'white' }}
            />
            <TextField label={'비디오굿즈 가격'} text={this.props.data.priceText()} />
            <TextField label={'서비스 수수료'} text={this.props.data.serviceChargeText()} />
            <Divider style={{ margin: 8, backgroundColor: this.props.theme.colors.primary }} />
            <TextField label={'합계'} text={this.props.data.totalText()} />
            <Button title="신청하기" containerStyle={{ margin: 8 }} onPress={this.onPressFinish.bind(this)} />
        </View>
    )

    onPressNextStep = () => {
        const key = `validateStep${this.state.step}`
        if (this[key] && this[key]() === false) {
            return
        }
        this.setState({
            step: this.state.step + 1
        }, () => {
            const key = `onStep${this.state.step}`
            if (this[key]) {
                this[key]()
            }
        })
    }

    onPressPrevStep = () => {
        this.setState({
            step: this.state.step - 1
        })
    }

    onChangeReceiver = (text) => {
        let error_message = this.validateReceiver(text)
        this.setState({ receiver: text, error_message })
    }

    onChangeSubject = (text) => {
        let error_message = this.validateSubject(text)
        this.setState({ subject: text, error_message })
    }

    validateReceiver = (text) => {
        if (text.length > 0) {
            return undefined
        }
        return '이름을 입력해주세요.'
    }

    validateSubject = (text) => {
        if (text.length > 0) {
            return undefined
        }
        return '주제를 입력해주세요.'
    }

    renderFooterByStep = () => {
        const key = `renderFooter${this.state.step}`
        if (this[key]) {
            return this[key]()
        }
        return null
    }

    renderFooter0 = () => (
        <View style={{ width: '100%' }}>
            <View
                style={{
                    width: '100%',
                    height: PAGE_FOOTER_HEIGHT,
                    bottom: 0,
                    backgroundColor: this.props.theme.colors.warning,
                    flexDirection: 'row'
                }}>
                <Text
                    style={{
                        flex: 2,
                        padding: 15,
                        fontSize: 15,
                        color: 'white',
                        alignSelf: 'center'
                    }}>음란한 주제나 가게 홍보 등 상업적 요청은 사용자에 의해 거부될 수 있습니다.</Text>
                <Button
                    style={{
                        flex: 1,
                        minWidth: 90,
                        justifyContent: 'center'
                    }}
                    title={`예시\n더보기`}
                    type='clear'
                    onPress={this.onPressExample}
                />
            </View>
        </View>
    )

    onPressExample = () => {
        this.setState({
            showExample: true
        })
    }

    onPressExampleClose = () => {
        this.setState({
            showExample: false
        })
    }

    onPressFinish = async () => {
        try {
            await GoodsAPI.request(this.props.data, {
                receiver: this.state.receiver,
                phone: this.state.phone,
                subject: this.state.subject
            })
            this.props.history.replace('/request/complete')
        } catch (err) {
            // TODO: 에러처리
            console.error(err)
        }
    }
}

export default compose(
    withHeader({
        left: {
            icon: 'chevron-left',
            iconSize: 15,
            action: (props) => props.history.goBack()
        },
        title: '비디오굿즈 신청하기'
    }),
    withRouter,
    fetch(props => `/goods/${props.location.state.item_id}`),
    withTheme
)(GoodsRequest);
